.drawer {
    background: #241b2f;
    padding: 12px;
    position: fixed;
    width: 100%;
    height: fit-content;
    box-shadow: 0px 4px 30px #00000088;
    z-index: 100;

    &.dir-bt {
        transition: top ease-in-out 250ms;
        top: 100%;
    }

    &.dir-rl {
        width: 85dvw;
        top: 0px;
        left: 100%;
        transition: right ease-in-out 250ms;
    }

    &.dir-lr {
        width: 85dvw;
        top: 0px;
        right: 100%;
        transition: left ease-in-out 250ms;
    }
}

.backclick {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000033;
    pointer-events: all;
    z-index: 99;
}