.message {
    padding: 0 6px;
    position: relative;
    overflow: hidden;

    @media (min-width: 600px) {
        border-radius: 12px;
        padding: 0 12px;
    }

    // .header {
    //     display: flex;
    //     gap: 8px;
    //     align-items: center;

    //     .time {
    //         flex: 1;
    //         font-size: .9rem;
    //     }

    //     button {
    //         text-decoration: underline;
    //         font-size: .9rem;
    //         margin: 0;
    //         padding: 0;
    //         color: #68bd6f;
    //         letter-spacing: -1px;
    //     }
    // }

    .wrap {
        background-color: #00430466;
        padding: 0 6px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 6px;
        position: relative;

        @media (min-width: 600px) {
            padding: 0 12px;
            margin-bottom: 12px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border: 2px solid #a0eb99;
            border-radius: 12px;
            opacity: 0;
            transition: opacity 2000ms ease;
        }

        .ivy {
            background: url(./../../images/ai.png) no-repeat;
            background-size: cover;
            background-position: center;
            min-width: 30px;
            width: 30px;
            height: 24px;
            border-radius: 12px;
            margin-top: 9px;
            margin-right: 4px;
            float: left;
        }

        .content {
            flex: 1;
        }
    }

    .text,
    p {
        margin: 24px 0;
        font-size: 1rem;
    }

    form {
        button[type="submit"] {
            padding: 8px 24px;
            font-size: 1rem;
            background: #1f7534;
            border-radius: 10px;
            width: 100%;

            &:focus, &:hover {
                background: #279141;
            }
        }
    }

    .options {
        margin: 0 -12px;
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        padding: 6px 12px;
        background: #00430422;

        @media (min-width: 600px) {
            padding: 12px 12px;
        }

        button {
            padding: 8px 24px;
            font-size: 1rem;
            background: #1f7534;
            border-radius: 10px;
            width: 100%;

            &:focus, &:hover {
                background: #279141;
            }
        }
    }
}

.fruit {
    position: relative;
    margin: 0 auto 12px;
    text-align: center;

    img {
        width: 32px;
        height: 32px;
    }

    .title {
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 700;
        color: #a0eb99;
    }
}

.fruitlist {
    padding-left: 2rem;

    .fruititem {
        font-size: 1rem;
        letter-spacing: -.5px;
        line-height: 1.15rem;
        margin: 12px 0;
        padding: 0 0 0 6px;
    }
}

.response {
    padding: 12px 6px;
    border-radius: 12px;
    margin: 0 0 6px;
    background-color: #00430444;
    display: flex;
    gap: 4px;

    @media (min-width: 600px) {
        margin-bottom: 12px;
        padding: 12px;
    }

    .you {
        width: 30px;
        text-align: center;
        letter-spacing: -2px;
        font-weight: 700;
        transform: scale(1.1) translate(0, -1px);
        color: #cbffca;
    }
}

.loader {
    background-color: #00000066;
    padding: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.spinner {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    box-shadow: 3px 3px 3px #af92ed inset;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.idea {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 12px 12px 0;

    button.add {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #6145bd;
    }
}

.low-fruit {
    margin: 0 0 6px;
    padding: 0px 6px 9px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 1;

    .fruit-wrap {
        position: relative;
        margin-bottom: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 30px;
            height: 30px;
            margin: 0 auto;
        }

        .pin {
            position: absolute;
            left: calc(50% + 30px);
            transform: translate(-50%, 0);
            opacity: .8;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            img {
                height: 20px;
                filter: invert(85%) sepia(10%) saturate(1045%) hue-rotate(332deg) brightness(51%) contrast(101%);

                &.pinned {
                    filter: invert(85%) sepia(10%) saturate(3045%) hue-rotate(332deg) brightness(101%) contrast(101%);
                    opacity: 1;
                }
            }

        }
    }

    &>.title {
        font-size: 1rem;
        font-weight: 700;
        color: #a0eb99;
        display: flex;
        gap: 9px;
        margin-bottom: 3px;
        justify-content: space-around;
        flex: 1;
        align-items: center;

        img {
            height: 20px;
            position: relative;
            filter: invert(69%) sepia(99%) saturate(752%) hue-rotate(354deg) brightness(101%) contrast(92%);
            opacity: .2;
            display: none;
        }
    }

    .detail {
        font-size: .9rem;
        display: flex;
        gap: 6px;
        align-items: center;
        margin: 0 auto;
        max-width: 100%;

        .label {
            white-space: nowrap;
        }

        .title {
            font-size: .9rem;
            font-weight: 700;
            color: #ffffff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        img {
            height: 10px;
            transform: rotate(180deg);
            filter: invert(19%) sepia(40%) saturate(6319%) hue-rotate(138deg) brightness(20%) contrast(76%);
        }
    }
}

.carousel {
    padding-top: 0;
    margin-bottom: 6px;

    :global(.carousel-item) {
        display: flex;
        flex-direction: column;
    }

    :global(.dots) {
        div {
            background-color: #68bd6f33;
            width: 16px;
            height: 16px;
        }

        :global(.current) {
            background-color: #68bd6f;
        }
    }
}

.spinner {
    width: 60px;
    height: 60px;
    margin: 12px auto;
}

.hint, .largehint {
    padding: 12px;
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 240px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 12px;

    img {
        max-width: 120px;
        max-height: 120px;
    }
}

.largehint {
    img {
        max-width: 40dvw;
        max-height: 40dvh;
        margin-bottom: 12px;
        border-radius: 6px;
    }
}