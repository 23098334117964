.multi-input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.enter-hint {
    font-size: .8rem;
    margin-left: 12px;
    opacity: .7;
}

.tags {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, .1);
    border-top: none;
    padding: 12px 12px 0;

    .tag {
        display: inline-block;
        padding: 12px;
        margin: 0 12px 12px 0;
        border-radius: 6px;
        background: #282d36;
        letter-spacing: 1px;

        label {
            margin-right: 6px;
        }

        button {
            display: inline-block;
            margin: 0;
            padding: 0;
            background: #442d8e;
            color: #ffffff;
            border: none;
            line-height: 18px;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            transition: scale 300ms ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            .char {
                display: inline-block;
                transform: scaleY(.85);
            }
        }
    }

    .no-options {
        font-size: .8rem;
        opacity: .4;
        margin-bottom: 12px;
        height: 30px;
        line-height: 30px;
    }
}