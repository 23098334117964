.carousel {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    container: carousel / inline-size;

    @media (min-width: 600px) {
        width: 600px;
        max-width: 100%;
    }

    .scroll-container {
        position: relative;
        width: 100cqi;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        box-sizing: content-box;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .items {
        display: flex;
        width: fit-content;
        height: 100%;
        position: relative;
        gap: 12px;
    }

    .carousel-item {
        width: 100cqi;
        max-width: 100%;
        scroll-snap-align: start;
    }
}

.dots {
    margin: 0 auto 12px;
    display: flex;
    gap: 6px;
    justify-content: center;

    .dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #33333333;

        &.current {
            background: #7515F9;
        }
    }
}