.disabled-field {
    flex: 1;
    align-items: center;
}

.row {
    position: relative;
    display: flex;
    gap: 12px;

    button {
        height: 48px;
        position: absolute;
        right: 0;
    }
}

.display-name {
    font-weight: 700;
    margin-bottom: 6px;
}