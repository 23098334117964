.topleftbuttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .back {
        position: absolute;
        left: 6px;
        top: -2px;
        padding: 0;
        min-width: 32px;
        height: 32px;
        background: #7515F9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            position: relative;
            left: -1px;
            max-width: 20px;
            max-height: 20px;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
        }
    }
}

.view {
    padding: 58.5px 12px 58px;
    height: 100dvh;
    overflow-y: auto;
    overscroll-behavior: none;
    position: relative;
    transition: padding-bottom 300ms ease;
    color: #efefef;

    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }
}

.content {
    width: 100%;
    height: calc(100dvh - 156px);
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 12px;
    font-family: 'Roboto', sans-serif;
}

.info {
    display: flex;
    align-items: end;
    margin-bottom: 12px;
    padding: 0 12px;

    .timestamp {
        font-size: .8rem;
        flex: 1;
    }

    .board {
        font-size: .8rem;
        background: #33333388;
        color: #efefef;
        padding: 2px 6px;
        border-radius: 3px;
    }
}

.editing,
.ivy {
    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }

    .top {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0 12px;

        .title {
            margin: 0;
            text-align: left;
            font-size: 2rem;
            text-shadow: -1px -1px 1px #000000aa;
            color: #ffffff;

            &:has(+button) {
                margin-bottom: 0;
            }
        }

        .mid {
            flex: 1;
        }

        .archive,
        .extract {
            background: none;
            padding: 0;
            margin: 0;
            text-decoration: underline;
        }
    }

    .counts {
        display: flex;
        gap: 12px;
        font-size: .8rem;
        padding: 0 12px;
        margin: 6px 0 0;
    }

    .selected-text {
        margin: 6px 0 24px;
        padding: 6px 12px;
        border: 1px solid #ffffff33;
        border-radius: 6px;
    }

    .setting-label {
        padding: 0 12px;
        margin: 12px 0 0;
        font-size: 1.3rem;
    }

    .buttons {
        display: flex;
        gap: 12px;
        margin-bottom: 12px;

        button {
            flex: 1;
            padding: 6px 12px;
            background: #7515F9;
            color: #ffffff;

            &:hover {
                background: #8731ff;
            }
        }
    }
}

.ivy {
    .top {
        justify-content: center;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        img {
            width: 46px;
            height: 46px;
            border-radius: 6px;
        }
    }
}

.row.row {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 12px;
    padding: 12px 0;
    align-items: start;

    &>div {
        flex: 1;
        width: 100%;
    }

    &>button {
        flex: 1;
        padding: 6px 12px;
        font-size: 1.2rem;
        background: #7515F9;

        &:hover {
            background: #8731ff;
        }
    }
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 12px;
    padding: 12px 0;
    align-items: start;

    &>div {
        flex: 1;
        width: 100%;
    }

    &>button {
        flex: 1;
        width: 100%;
        padding: 6px 12px;
        font-size: 1.2rem;
        background: #7515F9;
        border: 2px solid transparent;

        &:hover {
            background: #8731ff;
        }

        &:disabled {
            background: transparent;
            border: 2px dashed #b599db;
        }
    }
}

div.main-drawer {
    padding: 0;

    .controls {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 12px;
        height: 46px;
        max-width: 800px;
        margin: 0 auto;

        button {
            min-width: 120px;
            height: 58px;
            position: relative;
            top: -12px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 40px;
            font-size: 2rem;
            letter-spacing: -3px;
            background: #7515F9;
            color: #ffffff;
            height: 46px;

            &:hover {
                background: #8731ff;
            }

            img {
                width: 20px;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
            }

            &.filter {
                position: absolute;
                left: 12px;
                top: -12px;
                width: 46px;
                min-width: 46px;
                height: 46px;
                border-radius: 6px;
                box-shadow: 0px 0px 2px #ffffff66;
                width: 26px;
            }

            &.ivy {
                position: absolute;
                top: -12px;
                right: 12px;
                background: url(./../../../images/bb.png) no-repeat;
                background-size: cover;
                background-position: center;
                width: 46px;
                min-width: 46px;
                height: 46px;
                border-radius: 6px;
                box-shadow: 0px 0px 2px #ffffff66;
                transition: top 300ms ease;

                &.keyboard {
                    @media (max-width: 600px) {
                        top: 83px;
                    }
                }
            }
        }
    }
}

.header {
    font-size: 1.6rem;
    color: #7d56cb;
    font-weight: 700;
    text-align: center;
    margin: 18px 0 6px;
    letter-spacing: -2px;
}

.hide-underput {
    margin-bottom: -24px;
}

button.frwt.frwt {
    position: relative;
    font-weight: 700;
    font-size: 1.1rem;
    letter-spacing: -1px;
    max-width: 46px;
    height: 46px;
    padding: 6px;
    background: url(./../../../images/bb.png) no-repeat;
    background-size: cover;
    text-shadow: 1px 1px 1px #000000aa;
    box-shadow: 0px 0px 4px #ffffff33;

    &:hover {
        background: url(./../../../images/bb.png) no-repeat;
        background-size: cover;
    }
}

.subtitle {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin: 0 12px;
    padding: 12px 0 6px;

    .hint {
        font-weight: 400;
        color: #ffffff88;
    }
}

.boards {
    display: flex;
    flex-direction: column;
    border: 1px solid #eeeeee22;
    border-radius: 6px;
    max-height: 28dvh;
    overflow-y: auto;
    background: #33333333;

    button {
        background: #7515F9;
        text-align: left;
        padding: 6px 12px;
        color: #ffffff;
    }
}

button.editbutton.editbutton {
    border: 2px solid transparent;
    &:disabled {
        background: transparent;
        border: 2px dashed #b599db;
    }
}

.bb {
    display: flex;
    justify-content: center;
    padding: 24px 24px 12px;
    transition: transform 300ms ease-out;

    .block {
        position: relative;
        width: 120px;
        height: 120px;
        transition: transform 10000ms ease;
    }

    .blockloading {
        transform: scale(1.2);
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 10s linear infinite;
        box-shadow: 5px 5px 30px #2bc9e9 inset;
    }

    .detail {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 4s linear infinite;
        border-top: 4px solid #ffffff33;
        border-bottom: 4px solid #ffffff33;
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 600ms linear infinite;
        box-shadow: 2px 2px 16px #98dbe9 inset;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

button.ai-button.ai-button {
    padding: 6px;
    font-weight: 700;
    color: #ffffff;
    background: #2bc9e9;
    text-shadow: 0px 0px 3px #00000055;
}