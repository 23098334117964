.spinner {
    border-radius: 50%;
    width: 160px;
    height: 160px;
    animation: spin 1s linear infinite;
    box-shadow: 3px 3px 3px #af92ed inset;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}