.text {
    position: relative;
    display: inline-block;
    
    .display-text {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        span {
            opacity: 0;
            transition: opacity 100ms ease, margin-left 100ms ease;

            &.visible {
                opacity: 1;
                margin-left: -1px;
            }
        }
    }
    
    .space-text {
        opacity: 0;
    }
}