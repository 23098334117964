.filter-drawer {
    height: 100dvh;
    max-width: 400px;

    .scrollarea {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 100px;
    }

    .top {
        display: flex;

        .title {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            letter-spacing: -2px;
            margin-bottom: 12px;
            padding: 0 12px;
        }

        .mid {
            flex: 1;
        }

        .close {
            background: transparent;
            border: none;
            width: 28px;
            height: 28px;

            img {
                width: 16px;
                height: 16px;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
            }
        }
    }

    .subtitle {
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: -1px;
        margin: 0 12px;
        padding: 12px 0 6px;
    }

    .boards {
        display: flex;
        flex-direction: column;
        border: 1px solid #eeeeee22;
        border-radius: 6px;
        max-height: 33dvh;
        overflow-y: auto;
        background: #33333333;

        button {
            text-align: left;
            padding: 6px 12px;
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;

        button {
            padding: 6px 12px;
            font-size: 1rem;
            border: 1px solid rgba(255, 255, 255, .1);
        }
    }
}

.view {
    padding: 46.5px 0 0;
    height: 100dvh;
    overflow-y: auto;
    overscroll-behavior: none;
    position: relative;
    transition: padding-bottom 300ms ease;
    background: #e9e0c7;
}

.notes {
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 100%;
    max-width: 800px;
    margin: -4px auto 0;
    padding: 0 0 58px;

    @media (min-width: 800px) {
        border-left: 1px solid #33333333;
        border-right: 1px solid #33333333;
    }
}

.note {
    background: #e9e0c7;
    border-radius: 6px;
    padding: 12px;
    color: #333333;
    border-top: 1px solid #33333333;
    white-space: pre-wrap;
    word-break: break-word;

    .content-and-buttons {
        display: flex;
        align-items: start;
    }

    .text {
        flex: 1;
    }

    .tags {
        display: flex;
        gap: 3px;
        flex-wrap: wrap;
        margin-top: 6px;

        .tag {
            font-size: .8rem;
            color: #555555;
        }
    }

    .reminders {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        margin-top: 6px;

        .reminder {
            background: #b8d0f1;
            border-radius: 6px;
            padding: 6px;
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: .9rem;
            width: fit-content;

            img {
                width: 12px;
            }
        }
    }
}

.info {
    display: flex;
    align-items: end;
    margin-bottom: 6px;

    .timestamp {
        font-size: .8rem;
        flex: 1;
    }

    .board {
        font-size: .8rem;
        background: #33333388;
        color: #efefef;
        padding: 2px 6px;
        border-radius: 3px;
    }
}

.add,
.ivy {

    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }

    .top {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0 12px;

        .title {
            margin: 0;
            text-align: left;
            font-size: 2rem;
            text-shadow: -1px -1px 1px #000000aa;
            color: #ffffff;

            &:has(+button) {
                margin-bottom: 0;
            }
        }

        .mid {
            flex: 1;
        }

        .archive {
            background: none;
            padding: 0;
            margin: 0;
            text-decoration: underline;
        }
    }

    .setting-label {
        padding: 0 12px;
        margin: 12px 0 0;
        font-size: 1.3rem;
    }

    .row {
        display: flex;
        flex-direction: row;
        flex: 1;
        gap: 12px;
        padding: 12px 0;
        align-items: start;

        &>div {
            flex: 1;
            width: 100%;
        }

        &>button {
            flex: 1;
            padding: 6px 12px;
            font-size: 1.2rem;
            background: #7515F9;

            &:hover {
                background: #8731ff;
            }
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 12px;
        padding: 12px 0;
        align-items: start;

        &>div {
            flex: 1;
            width: 100%;
        }

        &>button {
            flex: 1;
            width: 100%;
            padding: 6px 12px;
            font-size: 1.2rem;
            background: #7515F9;
            border: 2px solid transparent;

            &:hover {
                background: #8731ff;
            }

            &:disabled {
                background: transparent;
                border: 2px dashed #b599db;
            }
        }
    }

    .buttons {
        display: flex;
        gap: 12px;
        margin-bottom: 12px;

        button {
            flex: 1;
            font-weight: 700;
            background: #7515F9;

            &:hover {
                background: #8731ff;
            }
        }
    }
}

.ivy {
    .top {
        justify-content: center;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        img {
            width: 46px;
            height: 46px;
            border-radius: 6px;
        }
    }
}

div.main-drawer {
    padding: 0;

    .controls {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 12px;
        max-width: 800px;
        margin: 0 auto;

        button {
            min-width: 120px;
            height: 58px;
            position: relative;
            top: -12px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 40px;
            font-size: 2rem;
            letter-spacing: -3px;
            background: #7515F9;
            height: 46px;

            &:hover {
                background: #8731ff;
            }

            img {
                width: 20px;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
            }

            &.filter {
                position: absolute;
                left: 12px;
                top: -12px;
                width: 46px;
                min-width: 46px;
                height: 46px;
                border-radius: 6px;
                box-shadow: 0px 0px 2px #ffffff66;
                width: 26px;
            }

            &.ivy {
                display: flex;
                border: none;
                position: absolute;
                right: 12px;
                top: -12px;
                background: url(./../../../images/bb.png) no-repeat;
                background-size: cover;
                background-position: center;
                width: 26px;
                min-width: 46px;
                height: 46px;
                border-radius: 6px;
                box-shadow: 0px 0px 2px #ffffff66;
            }
        }
    }
}

.header {
    font-size: 1.6rem;
    color: #7d56cb;
    font-weight: 700;
    text-align: center;
    margin: 18px 0 6px;
    letter-spacing: -2px;
}

.hide-underput {
    margin-bottom: -24px;
}

button.frwt.frwt {
    position: relative;
    font-weight: 700;
    font-size: 1.1rem;
    letter-spacing: -1px;
    max-width: 46px;
    height: 46px;
    padding: 6px;
    background: url(./../../../images/bb.png) no-repeat;
    background-size: cover;
    text-shadow: 1px 1px 1px #000000aa;
    box-shadow: 0px 0px 4px #ffffff33;

    &:hover {
        background: url(./../../../images/bb.png) no-repeat;
        background-size: cover;
    }
}

button.scrolldown.scrolldown {
    position: fixed;
    top: calc(100%);
    left: calc(50% - 24px);
    background: #FF7978;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0px 2px 16px -3px rgba(0, 0, 0, 0.7);
    transition: top 300ms ease;

    &:hover {
        background: #ff8c8c;
    }

    &.show {
        top: calc(100% - 140px);
    }

    img {
        width: 20px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
    }
}

.nonotes {
    position: relative;
    top: -20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #333333aa;
}

.bb {
    display: flex;
    justify-content: center;
    padding: 24px 24px 12px;
    transition: transform 300ms ease-out;

    .block {
        position: relative;
        width: 120px;
        height: 120px;
        transition: transform 10000ms ease;
    }

    .blockloading {
        transform: scale(1.2);
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 10s linear infinite;
        box-shadow: 5px 5px 30px #2bc9e9 inset;
    }

    .detail {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        transition: transform 1000ms ease;
        animation: offspin 4s linear infinite;
        border-top: 4px solid #bce0e533;
        border-bottom: 4px solid #bce0e533;
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 600ms linear infinite;
        box-shadow: 2px 2px 16px #98dbe9 inset;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes offspin {
    0% {
        transform: rotate(0deg) rotateY(0deg) scale(1.05);
    }

    50% {
        transform: rotate(0deg) rotateY(30deg) scale(1);
    }

    100% {
        transform: rotate(360deg) rotateY(0deg) scale(1.05);
    }
}

button.ai-button.ai-button {
    padding: 6px;
    font-weight: 700;
    color: #ffffff;
    background: #2bc9e9;
    text-shadow: 0px 0px 3px #00000055;
}

.minis {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    button {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        padding: 6px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        img {
            width: 16px;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
        }
    }
}

.recorddot {
    margin: 60px 0;
    border: 3px solid #ffffff33;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    animation: start 300ms ease;

    &.recording {
        border-color: #ed0c0c;
    }
}

@keyframes start {
    0% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

.instruct {
    font-size: 1rem;
    color: #ffffffdd;
}

.bclose {
    margin-top: 30px;

    img {
        display: block;
        width: 20px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
    }
}