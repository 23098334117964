.signup {

    .logo {
        text-align: center;
        box-sizing: border-box;
        max-width: 600px;
        margin: 0 auto;
        padding: 60px 24px 24px;

        @media (min-width: 624px) {
            padding: 60px 24px 24px;
            margin: 60px auto;
        }

        img {
            width: 100%;
        }

        a {
            text-decoration: none;
        }
    }

    .legal {
        margin: 0;

        p {
            margin: 0;
            font-size: .8rem;
            letter-spacing: 0;
        }

        input {
            margin-right: 12px;
            position: relative;
            top: 2px;
        }
    }

    .no-terms-error {
        margin: 0 0 27px;
    }

    .terms-error {
        font-size: .8rem;
        margin-bottom: 12px;
        color: #ffaaaa;
        padding: 0 12px;
    }

    .controls {
        text-align: center;

        p {
            line-height: 1.1rem;
        }

        .create {
            width: 100%;
            background: #7515F9;
        }

        .pressandhold {
            font-size: .8rem;
            color: #aaaaaa;
            margin-bottom: 12px;
        }

        button {
            width: 100%;
        }
    }

    .login-here {
        text-align: center;

        a {
            color: #ffffff;
        }
    }

    .container {
        background: #00000033;
        border-color: transparent;
        margin-bottom: 36px;

        a {
            color: #bca8fd;
        }
    }
}