* {
  box-sizing: border-box;
}

html,body {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #4d3e60;
  color: #eeeeee;
}

a {
  color: #532eba;
}

button {
  font-family: 'Roboto', sans-serif;
}

//

g.react-flow__edge path {
  stroke-width: 20;
  stroke: #503e1a;
}

div.react-flow__handle {
  background: #503e1a !important;
  border-color: #332813;
  width: 24px;
  height: 24px;
  // display: none;
  visibility: hidden;
}

textarea {
  resize: none;
}