.autocomplete-wrap {
    position: relative;
    
    .panel {
        position: absolute;
        top: calc(100% - 18px);
        left: 0;
        width: 100%;
        background: #382e57;
        border-radius: 6px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .4);
        overflow: hidden;
        z-index: 100;
        max-height: 260px;
        overflow-y: auto;

        &.above {
            top: unset;
            bottom: calc(100% + 12px);
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                button {
                    padding: 0;
                    margin: 0;
                    background: none;
                    border: none;
                    color: #ffffff;
                    margin: 0;
                    padding: 12px;
                    width: 100%;
                    text-align: left;
                    outline: none;
    
                    &:hover {
                        background: #4e3c6e;
                    }
                }
            }
        }
    }
}