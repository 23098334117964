.login {
    .logo {
        text-align: center;
        padding: 60px 24px 24px;
        box-sizing: border-box;
        max-width: 600px;
        margin: 0 auto;
        @media (min-width: 624px) {
            margin: 60px auto;
        }
        img {
            width: 100%;
        }
        a {
            text-decoration: none;
        }
    }

    .controls {
        .login {
            width: 100%;
            background: #7515F9;
        }
    }

    .error {
        text-align: center;
        height: 32px;
        
        .message {
            font-size: .8rem;
        }
    }

    .signup-here {
        text-align: center;
        line-height: 2rem;

        a {
            color: #ffffff;
        }
    }

    .container {
        background: #00000033;
        border-color: transparent;
    }
}