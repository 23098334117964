body {
    overscroll-behavior: none;

    .ivy-drawer {
        background-image: linear-gradient(to bottom, #2c4c3833, #1f3427aa), url(./images/ivy_bg.png);
        background-size: cover;
        background-position: center;
        padding: 12px 12px 0;
        overscroll-behavior: none;
        height: calc(100dvh - 45px);
        display: flex;
        flex-direction: column;

        @media (min-width: 600px) {
            padding-top: 24px;
        }

        &.tutorial, &.tutorial.focus-mode {
            background: linear-gradient(to bottom, #0b140faa, #090f0bdd);

            &::before {
                opacity: .4;
            }
        }

        &.focus-mode {
            background-image: linear-gradient(to bottom, #0b140faa, #090f0bdd), url(./images/ivy_bg.png);

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                backdrop-filter: blur(10px);
                z-index: -1;
                opacity: .7;
            }

            .notifications {
                background: transparent;
                box-shadow: none;

                .wrap {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    &>div {
                        display: none;

                        &.current {
                            display: block;

                            &>div {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                background: transparent;

                                &::before {
                                    display: none;
                                }

                                p {
                                    font-size: 1.8rem;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            :global(.ivy) {
                width: 50px;
                aspect-ratio: 1;
                height: auto;
            }
        }

        .logo {
            display: flex;
            justify-content: center;
            background: #153c1422;
            backdrop-filter: blur(1px);
            width: 100%;
            padding: 0px 6px;
            border-radius: 12px;
            margin: 0 auto 6px;

            img {
                max-width: 40px;

                @media (min-width: 600px) {
                    max-width: 80px;
                }
            }
        }

        .notifications {
            position: relative;
            width: 100%;
            background: #658f69;
            border-radius: 18px;
            margin-bottom: 12px;
            overflow-y: auto;
            pointer-events: all;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            /* Use align-items to align children to the bottom */
            -webkit-overflow-scrolling: touch;
            box-shadow: inset 0px 0px 4px #000000aa;
            overscroll-behavior: none;
            flex: 1;

            @media (min-width: 600px) {
                max-width: 600px;
                margin: 0 auto 12px;
            }

            .wrap {
                padding: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                /* Make sure it takes full width */
                height: fit-content;
                /* Ensure .wrap expands to contain content */
            }

            .current {
                &>div:first-of-type {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        .controls {
            margin-bottom: 12px;
            display: flex;
            justify-content: center;

            .middle-wrap {
                position: relative;

                & > button {
                    background: #6145bd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
    
                    img {
                        width: 20px;
                        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
                    }
                }

                .right {
                    position: absolute;
                    left: calc(100% + 12px);
                    top: 0;

                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        padding: 9px;

                        &:active {
                            img {
                                transform: rotate(360deg);
                            }
                        }
        
                        img {
                            width: 26px;
                            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%);
                            transition: transform 300ms ease;
                        }
                    }
                }
            }
        }
    }
}