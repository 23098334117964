.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8.5px 0;
    background: #241b2f;
    z-index: 10;
    box-shadow: 0px -4px 30px #00000088;

    .wrap {
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
    }

    .logo {
        display: flex;

        img {
            height: 28px;
        }
    }

    .profile {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 12px;
        top: 0;
        background: transparent;
        border: none;
        padding: 2px;

        img {
            width: 100%;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
        }
    }

    .reviews {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 56px;
        top: 0;
        background: transparent;
        border: none;
        padding: 2px 4px 4px;

        img {
            width: 100%;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
        }

        .number {
            position: absolute;
            font-size: .5rem;
            line-height: .5rem;
            letter-spacing: -.03rem;
            background-color: #ff3333;
            color: #ffffff;
            font-weight: 700;
            padding: 4px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            top: 12px;
            top: 16px;
            left: 18px;
            display: flex;
            justify-content: center;
        }
    }
}

.profile-drawer {
    height: 100dvh;
    max-width: 400px;

    .top {
        display: flex;

        .title {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            letter-spacing: -2px;
            margin-bottom: 12px;
            padding: 0 12px;
        }

        .mid {
            flex: 1;
        }

        .close {
            background: transparent;
            border: none;
            width: 28px;
            height: 28px;

            img {
                width: 16px;
                height: 16px;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
            }
        }
    }

    hr {
        margin: 12px 0;
        opacity: .1;
    }

    .buttons {
        text-align: right;
    }
}

.menubuttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
        font-size: 2rem;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 12px;

        img {
            width: 32px;
            height: 32px;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
        }
    }
}

.review-drawer {
    max-height: 80dvh;
    height: fit-content;

    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }

    .top {
        display: flex;

        .title {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            letter-spacing: -2px;
            margin-bottom: 12px;
            padding: 0 12px;

            .count {
                font-weight: 400;
            }
        }

        .mid {
            flex: 1;
        }

        .close {
            background: transparent;
            border: none;
            width: 28px;
            height: 28px;

            img {
                width: 16px;
                height: 16px;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(101%);
            }
        }
    }

    .review, .emptyreview {
        display: flex;
        flex-direction: column;
        height: 60dvh;
        background: rgba(255, 255, 255, .1);
        border-radius: 24px;
        margin-bottom: 12px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, .3);

        &>.text {
            background: #E9E0AF;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            padding: 18px 12px;
            color: #333333;
            height: 200px;
            overflow-y: auto;
            white-space: pre-wrap;
            word-break: break-word;
        }

        .changes {
            padding: 0 12px;
            overflow-y: auto;
            flex: 1;
        }

        .grouptitle {
            font-weight: 700;
            margin: 12px 0;
        }

        .reminders {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 6px;

            .reminder {
                background: #b8d0f1;
                border-radius: 6px;
                padding: 12px;
                display: flex;
                align-items: center;
                gap: 6px;
                font-size: .9rem;
                color: #333333;

                img {
                    width: 12px;
                }

                .text {
                    flex: 1;
                }

                .remove {
                    button {
                        padding: 6px;

                        img {
                            width: 12px;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .emptyreview {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        color: #ffffff33;
    }

    .row {
        display: flex;
        gap: 12px;
        margin-bottom: 12px;

        button {
            flex: 1;
        }
    }
}

.timestamp {
    font-size: .8rem;
    margin-bottom: 6px;
}